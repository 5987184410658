import { 
    SiFirebase,
    SiRedux,
    SiPytorch,
    SiPandas,
    SiPlotly,
    SiScikitlearn,
    SiNumpy,
    SiPytest,
    SiLeaflet,
    SiFastapi,
    SiRedis,
    SiDocker,
} from "react-icons/si";
import { FaReact } from "react-icons/fa";
import { RiFlashlightFill } from "react-icons/ri";
import { BiLogoPostgresql } from "react-icons/bi";

export const projectData = [
    {   
        title: 'Safe Hood Map',
        description: `Inspired by backpacking in South America,
         I built a web app where users rate safety by location to help each other travel risk-free with crowdsourced data.
         The data gets aggregated on a neighborhood level to be displayed for the community.`,
        imagePath: '/portfolio/safehoodmap.webp',
        projectLink: 'https://safehoodmap.com',
        techStack: [
            {
                icon: FaReact,
                text: "React",
            },
            {
                icon: SiRedux,
                text: "Redux: Application state",
            },
            {
                icon: SiLeaflet,
                text: "Leaflet: Map user interface",
            },
            {
                icon: SiFastapi,
                text: "FastAPI: Python back-end framework",
            },
            {
                icon: BiLogoPostgresql,
                text: "PostgreSQL: Database with PostGIS extension for geospatial data",
            },
            {
                icon: SiRedis,
                text: "Redis: Caching and session management",
            },
            {
                icon: SiDocker,
                text: "Docker: Containerize the app for reproducible deployment on my server",
            },
        ],
        lessonsLearned: [
            `Engineers certainly underestimate time to solution. I am one of them.
             There are tons of actions in my backlog about small and big improvements.
             I had to discard too many ideas and it was painful.
             Even so, I arrived at the prototype readiness stage a month later than estimated.`,
            `Ship a usable product as soon as possible. Let people use it. Shut up and watch them mindfully.
             I heard UX designers tell me how users do unexpected weird things. It's much more fun to watch it.
             One example is when multiple people wanted to click unclickable elements while there were other HUGE elements nearby that looked obviously interactive.
             Obviously, to me.
             I had to step back, accept my faulty UX understanding, and implement what felt natural for users.
             It was the right decision.`,
            `Deploying a web app is really affordable if you know where to pay and where to DIY.
             I found the right balance between cost and effort hosting my Docker images on Railway.
             Deploying everything costs just 5$ per month.`,
            `Mobile client version of the web app requires MUCH extra care.
             I stumbled into many issues, ranging from minor ones (e.g. 'vh' CSS property does not accurately measure the screen height on mobile)
             to major ones (e.g. Polygons in Leaflet do not get displayed in certain browsers).`,
        ],
    },
    {
        title: 'Podcast Website',
        description: 'Being the podcast host, I also developed the website where you find the links, photos and description of the episodes.',
        imagePath: '/portfolio/data-for-future.webp',
        projectLink: 'https://dataforfuture.vercel.app',
        techStack: [
            {
                icon: FaReact,
                text: "React"
            },
            {
                icon: SiRedux,
                text: "Redux: Application state",
            },
            {
                icon: SiFirebase,
                text: "Firebase: Database and file storage",
            },
        ],
        lessonsLearned: [
            `SQL and Python proved to still be very useful skills, even though the web is purely built with React & JavaScript.
             This project was a migration from WordPress.
             Unfortunately, I lost access to the WordPress interface, so I downloaded the MariaDB SQL file and other files via FTP connection.
             I quickly realized that WordPress is full of trash.
             To locate the data that I could actually reuse,
             I queried and filtered the SQL file to extract the text data (episode titles, descriptions, links, etc.),
             and tried to grasp the pattern in the file structure to locate the images.
             Then I used Python to clean the data and fix inaccuracies, sometimes manually. This was extremely useful.
             Of 10 GB of data on the FTP server, only 2 MB of text files and images were actually required!!!`,
            `I realized that generally, whenever I have a doubt of how to implement a feature intelligently,
             the best place to start is to look at how prominent companies do it.
             More specifically, while loading the data to present a list of episodes,
             it became clear that loading all data puts too much load on the database and slows down the app.
             Using Apple Podcasts website as inspiration, I implemented a 'load more' functionality which sped up the load times significantly.`,
        ],
    },
    {
        title: 'Crypto Trading Bot',
        description: 'Machine Learning project to predict crypto prices.',
        imagePath: '/portfolio/crypto-bot.webp',
        projectLink: 'https://github.com/pavlo-seimskyi/crypto-bot',
        techStack: [
            {
                icon: SiPytorch,
                text: "PyTorch to train LSTM and transformer models",
            },
            {
                icon: SiNumpy,
                text: "NumPy for feature processing",
            },
            {
                icon: SiPandas,
                text: "Pandas for data exploration",
            },
            {
                icon: SiPlotly,
                text: "Plotly and other libraries for visualizations",
            },
            {
                icon: SiPytest,
                text: "PyTest for unit tests",
            },
            
        ],
        lessonsLearned: [
            "Using only technical indicators at 1-minute granularity is not enough to accurately predict the future crypto price.",
            "Backtesting is extremely important!",
            "It was a great idea to start with assessing the feasibility before jumping into building the bot functionality. I could have spent months on building portfolio managers, monitoring systems, and alike, but it's all irrelevant if my price prediction is pretty much random.",
            "I got much more comfortable using Transformer and LSTM architectures, as well as the Dependency Inversion principle.",
        ],
    },
    {
        title: 'Filler Sound Detector',
        description: 'Detect distracting uhm\'s in speech using neural networks.',
        imagePath: '/portfolio/filler-sound-detector.webp',
        projectLink: 'https://github.com/pavlo-seimskyi/filler-sound-detector',
        techStack: [
            {
                icon: SiPytorch,
                text: "PyTorch to training multi-layer perceptron and LSTM models to beat the baseline",
            },
            {
                icon: RiFlashlightFill,
                text: "LightGBM to train as the baseline model",
            },
            {
                icon: SiScikitlearn,
                text: "Scikit-learn to try simpler ML models for prototyping",
            },
            {
                icon: SiPytest,
                text: "PyTest for unit tests",
            },
        ],
        lessonsLearned: [
            "You need a whole LOT of data to train a speech-related model. With just 20h of audio I had I could not achieve any reasonable results.",
            "It is always a great idea to start a project with a baseline solution. Initially, I thought LightGBM would not handle the task well as it could not handle the 2D sequential data which I saw as a fundamental part of the solution. However, it turned out that beating 'simple' LGBM model was nothing easy.",
            "I finally refreshed my PyTorch knowledge. It is a great library and I really enjoy working with it.",
        ],
    },
    {
        title: 'Landcover Type Prediction with Satellite Imagery',
        description: 'Classify landcover type multi-spectral satellite imagery from Sentinel-2 mission.',
        imagePath: '/portfolio/landcovernet.webp',
        projectLink: 'https://github.com/pavlo-seimskyi/semantic-segmentation-satellite-imagery',
        techStack: [
            {
                icon: SiPytorch,
                text: "Fast.ai (built on top of PyTorch) for training the U-net segmentation model",
            },
        ],
        lessonsLearned: [
            "All the true power of most Deep Learning applications lies in pretrained models. It is absolutely amazing that I can get a 70% accuracy on a 6-class satellite imagery dataset using just RGB colors.",
            "Data on the internet is really messy. For instance, a lot of satellite images contained clouds. Thankfully, I could clean the data using a Cloud Mask band provided in the Sentinel-2 data.",
            "It is so awesome that satellite images are available to everyone at any time at 10m resolution. There are hundreds of potential applications of this data combined with AI, and I am looking forward to exploring new ideas using satellite data.",
        ],
    },
];
