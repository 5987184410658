const jobsData = [
    {
        title: "Software Engineer",
        companyName: "Telavox",
        companyLogoPath: "/jobs/telavox.webp",
        startDate: "Jun 2024",
        endDate: "Present",
        description: [
            "Boosted system throughput 400% (40 to 200+ events/second) by implementing parallel processing with efficient locking mechanisms.",
            "Built end-to-end testing framework with 30+ automated tests, uncovering critical bugs pre-production.",
            "Automated Elasticsearch index management, reducing migration time from several hours to 15 minutes and eliminating manual work.",
            "Drove code quality initiatives including automated formatting and modular architecture design.",
        ],
    },
    {
        title: "Data Scientist",
        companyName: "Glovo",
        companyLogoPath: "/jobs/glovo.webp",
        startDate: "Jan 2021",
        endDate: "Sep 2023",
        description: [
            "Implemented data science models in production resulting in a €600K annual improvement in marketing budget allocation.",
            "Conducted and assessed various statistical experiments, directly contributing to a €450K increase in yearly profits.",
            "Owner of lifetime value analytics supporting multi-million EUR annual marketing investment in a cross-disciplinary team.",
        ],
    },
    {
        title: "Machine Learning Engineer",
        companyName: "Omdena",
        companyLogoPath: "/jobs/omdena.webp",
        startDate: "Oct 2020",
        endDate: "Jan 2021",
        description: [
            "Working in a team of 50+ Data Scientists around the world as well as Kenya Red Cross, I developed AI-powered solutions to assess the impact of locusts in Kenya and leverage more precise and robust humanitarian aid.",
            "Harnessed publicly available training data, cleaning it and reducing the dataset size from 250 GB to 350 MB.",
            "Collaborated in exploratory data analysis of imagery from Sentinel 2 satellite as well as drones.",
        ],
    },
    {
        title: "Podcast Host & Web Developer",
        companyName: "Data For Future",
        companyLogoPath: "/jobs/data-for-future.webp",
        startDate: "Mar 2020",
        endDate: "Mar 2023",
        description: [
            "Produced 50+ episodes interviewing industry experts on Data, Artificial Intelligence, and Sustainability topics.",
        ],
    },
]

export default jobsData;
